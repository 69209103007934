export const DRAWERWIDTH: number = 220;
export const ENGLISH = "English";
export const ESPANOL = "Español";
export const COLORS = {
  bar: "#D1D5DB",
  title: {
    text: "#fff",
    background: "#F59E0B",
  },
};
